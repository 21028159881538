import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class RedirectService {
  logout(redirect?: "login" | "home"): void {
    if (environment.settings.redirect) {
      localStorage.removeItem("currentUser");
      if (redirect === "login") {
        this.redirectSSOLogin();
      } else {
        window.location.href = environment.frontend.secdojo;
      }
    } else {
      console.log(
        "[Auth - Redirect] [logout] %cRedirect is disabled",
        "color:red"
      );
    }
  }

  redirectSSOLogin() {
    if (environment.settings.redirect) {
      window.location.href = `${environment.frontend.account}/login/validate?redirect_url=${environment.frontend.cyberlearn}`;
    } else {
      console.log(
        "[Auth - Redirect] [redirectSSOLogin] %cRedirect is disabled",
        "color:red"
      );
    }
  }
}
